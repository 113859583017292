import { storeToRefs } from "pinia";

export default function useCalculatorValues() {
  const store = useCalculatorStore();
  const { setFromValue, setChangedValue } = store;
  const { fromValue, toValue, course, pairData, changedValue } =
    storeToRefs(store);

  const round = computed(() => {
    return {
      from: pairData.value?.from.roundCalculator ?? 0,
      to: pairData.value?.to.roundCalculator ?? 0,
    };
  });

  // if (fromValue.value && course.value) {
  //   toValue.value = roundNumber(fromValue.value * course.value, round.value.to);
  // }

  const limits = computed(() => {
    const from = pairData.value?.from;
    const to = pairData.value?.to;
    if (!from || !to)
      return {
        from: {
          min: 0,
          max: 0,
        },
        to: {
          min: 0,
          max: 0,
        },
      };
    return {
      from: {
        min: from.min ? from.min : to.min / course.value!,
        max: from.max,
      },
      to: {
        min: to.min ? to.min : from.min * course.value!,
        max: to.max,
      },
    };
  });

  const handleInputFrom = (value: number) => {
    fromValue.value = value;
    toValue.value = roundNumber(value * course.value!, round.value.to);
  };

  const handleInputTo = (value: number) => {
    fromValue.value = roundNumber(value / course.value!, round.value.from);
    toValue.value = value;
  };

  const handleChangeFrom = (value: number) => {
    if (value < limits.value.from.min) {
      setFromValue(limits.value.from.min);
      toValue.value = roundNumber(
        limits.value.from.min * course.value!,
        round.value.to,
      );
    } else if (value > limits.value.from.max) {
      setFromValue(limits.value.from.max);
      toValue.value = roundNumber(
        limits.value.from.max * course.value!,
        round.value.to,
      );
    }
    setChangedValue("from");
  };

  const handleChangeTo = (value: number) => {
    handleChangeFrom(roundNumber(value / course.value!, round.value.from));
    setChangedValue("to");
    // toValue.value = value;
    // setFromValue(roundNumber(value / course.value!, round.value.from));
  };

  watch(course, () => {
    if (changedValue.value === "from") {
      toValue.value = roundNumber(
        fromValue.value! * course.value!,
        round.value.to,
      );
    } else {
      fromValue.value = roundNumber(
        toValue.value! / course.value!,
        round.value.from,
      );
    }
  });

  return {
    fromValue,
    toValue,
    round,
    handleInputFrom,
    handleInputTo,
    handleChangeFrom,
    handleChangeTo,
  };
}
