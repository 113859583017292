import type {
  Direction,
  DirectionWithId,
  OptionListProps,
} from "~/types/calculator";

export function getDirectionsList(
  directions: Direction[],
  ids: number[],
): DirectionWithId[] {
  return directions
    .filter((direction) => ids.some((id) => direction.ids.includes(id)))
    .map((direction) => ({
      ...direction,
      id: ids.find((id) => direction.ids.includes(id)) as number,
    }));
}

export function getDirectionItem(
  directions: Direction[],
  id: number,
): DirectionWithId | null {
  const direction = directions.find((dir) => dir.ids.includes(id));
  return direction ? { id, ...direction } : null;
}

export function getCurrentSbpName(optionList: any, inputsValue: any) {
  if (!inputsValue) return "Выберите Банк";
  return optionList.find((item: OptionListProps) => inputsValue === item.id)
    .name;
}
