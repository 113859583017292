import { storeToRefs } from "pinia";
import type { Direction } from "~/types/calculator";
import {
  getDirectionItem,
  getDirectionsList,
} from "~/utils/calculator/directions";

export default async function useCalculatorData(directions: Direction[]) {
  const store = useCalculatorStore();

  const { initCalculator } = store;
  const {
    fromCurrencyId,
    toCurrencyId,
    fromCurrencies,
    toCurrencies,
    pairData,
  } = storeToRefs(store);
  if (!fromCurrencyId.value || !toCurrencyId.value) {
    await initCalculator();
  }

  const fromDirection = computed(() => {
    if (!fromCurrencyId.value) return null;
    return getDirectionItem(directions, fromCurrencyId.value);
  });

  const toDirection = computed(() => {
    if (!toCurrencyId.value) return null;
    return getDirectionItem(directions, toCurrencyId.value);
  });

  const fromCurrencyData = computed(() => {
    if (!fromDirection.value)
      return {
        title: "RUB",
        logo: "/images/home/tempSberIcon.png",
        name: "",
      };
    return {
      title: fromDirection.value.currency[0],
      logo: fromDirection.value.logo.simple,
      name: fromDirection.value.name,
    };
  });

  const toCurrencyData = computed(() => {
    if (!toDirection.value)
      return {
        title: "USD",
        logo: "/images/home/tempSberIcon.png",
        name: "",
      };
    return {
      title: toDirection.value.currency[0],
      logo: toDirection.value.logo.simple,
      name: toDirection.value.name,
    };
  });

  const fromDirections = computed(() => {
    if (!fromCurrencies.value) return [];
    return getDirectionsList(directions, fromCurrencies.value);
  });

  const toDirections = computed(() => {
    if (!toCurrencies.value) return [];
    return getDirectionsList(directions, toCurrencies.value);
  });

  const bonus = computed(() => {
    if (!pairData.value) return 0;
    return pairData.value.bonus;
  });

  const congestion = computed(() => {
    if (!pairData.value) return null;
    return pairData.value.structure.congestion;
  });

  const notifications = computed(() => {
    if (!pairData.value) return null;
    const notifications = [];
    if (pairData.value.from.notify) {
      notifications.push({
        currency: fromCurrencyData.value.name,
        message: pairData.value.from.notify,
      });
    }
    if (pairData.value.to.notify) {
      notifications.push({
        currency: toCurrencyData.value.name,
        message: pairData.value.to.notify,
      });
    }
    return notifications;
  });

  return {
    fromCurrencyData,
    toCurrencyData,
    fromDirections,
    toDirections,
    bonus,
    congestion,
    notifications,
  };
}
